import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';

import type { DateFilter, ElasticSearchSortOptions, SortOptions } from '../SharedTypes';

import { FILTER_ORDERS } from './orders.graphql';

import type { Order, OrderSortableFields, OrderStateStageFilter } from './orders.types';
import { exchangeOrdersFilter } from '../../Helpers';

interface OrdersResponse {
  data: Order[];
  total: number;
  pageCount: number;
}

export interface FilterQueryResponse {
  filterOrders: OrdersResponse;
}

interface InitialOrderQueriesVariables {
  perPage: number;
  order?: SortOptions | ElasticSearchSortOptions;
  orderBy?: OrderSortableFields;
}

interface OrderQueriesVariables
  extends InitialOrderQueriesVariables,
    OrderStateStageFilter,
    DateFilter {
  page: number;
  search?: string;
  retailerId?: string[];
  dropProfileType?: string;
  dropProfile?: string;
  dispatchZone?: string[];
  toCity?: string[];
  fromCity?: string[];
  partnerCategoryIds?: string[];
  driverId?: string[];
  isInternational?: boolean;
  isRemote?: boolean;
  isReverse?: boolean;
  or?:
    | Array<{
        dropProfileKey?: string[];
        isReverse?: boolean;
        isExpress?: boolean;
        isInternational?: boolean;
        isRemote?: boolean;
        status?: string[];
      }>
    | {
        dropProfileKey?: string[];
        isReverse?: boolean;
        isExpress?: boolean;
        isInternational?: boolean;
        isRemote?: boolean;
        status?: string[];
      };
  status?: string[];
  stopStatus?: string[];
  failedAttemptCount?: number;
  validationStatus?: string[];
  flagType?: string[];
  not?: {
    flagType?: string[];
  };
}

export function useFilterOrdersQuery(
  initialVariables: InitialOrderQueriesVariables,
  onCompleted?: (orders: Order[]) => void
) {
  const [query, { data, loading, called }] = useLazyQuery<FilterQueryResponse>(FILTER_ORDERS, {
    variables: initialVariables,
    pollInterval: 30000,
    onCompleted: (data) => onCompleted?.(data.filterOrders.data),
  });

  const getOrders = useCallback(
    (variables: Partial<OrderQueriesVariables>) => {
      QueriesStore.update('FILTER_ORDERS', { ...initialVariables, ...variables });
      void query({ variables });
    },
    [initialVariables, query]
  );

  return {
    getOrders,
    called,
    orders: exchangeOrdersFilter(data?.filterOrders?.data ?? []),
    totalOrders: data?.filterOrders?.total ?? 0,
    pageCount: data?.filterOrders?.pageCount ?? 0,
    loading,
  };
}
