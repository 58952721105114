import { type FC } from 'react';
import {
  Box,
  IconWText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PencilIcon,
  SBMenuDotsVerticalIcon,
  SBModal,
  SBUserDeleteIcon,
  toast,
  useDisclosure,
  Text,
} from '@swftbox/style-guide';
import {
  useRemoveSuperDropProfileMutation,
  useSetSuperDropProfilePrimary,
  useUpdateSuperDropProfiles,
} from 'src/components/Particles';
import BookmarkIcon from 'src/assets/icons/General/bookmark.svg';
import ToggleStatusIcon from 'src/assets/icons/General/setting03.svg';
import SVG from 'react-inlinesvg';

interface Props {
  retailerId: string;
  isPrimary: boolean;
  isActive: boolean;
  cities: string[];
  groupId: string;
  onEdit: () => void;
}

export const SuperDropProfileActionButtons: FC<Props> = ({
  retailerId,
  onEdit,
  groupId,
  isPrimary,
  isActive,
}) => {
  const { removeSuperDropProfile, loading } = useRemoveSuperDropProfileMutation();
  const { mutate: updateProfiles } = useUpdateSuperDropProfiles();
  const { mutate: setAsPrimary } = useSetSuperDropProfilePrimary();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onConfirm = async () => {
    if (loading) return;
    try {
      const { data } = await removeSuperDropProfile(groupId);
      toast.success(data?.removeSuperDropProfile.message);
    } catch (error) {
      toast.error('Failed to delete some or all super drop profiles');
    }
  };

  const handleSetPrimary = async () => {
    await setAsPrimary({
      variables: { markPrimaryInput: { id: groupId, retailerId } },
    });
  };

  const handleToggleStatus = async () => {
    await updateProfiles({
      variables: {
        updateSuperDropProfilesInput: {
          groupId,
          isActive: !isActive,
        },
      },
    });
  };
  return (
    <>
      <Menu>
        <MenuButton as="button">
          <SBMenuDotsVerticalIcon width="15px" />
        </MenuButton>
        <MenuList minW="170px">
          <MenuItem
            onClick={() => {
              onEdit();
            }}
          >
            <IconWText
              text="Edit"
              spacing="20px"
              Icon={<PencilIcon width="15px" viewBox="0 0 16 16" />}
            />
          </MenuItem>

          {!isPrimary && isActive && (
            <MenuItem padding={2} onClick={handleSetPrimary}>
              <IconWText
                text="Set as primary"
                spacing="20px"
                Icon={<SVG src={BookmarkIcon} width="16px" stroke="currentColor" />}
              />
            </MenuItem>
          )}
          {!isPrimary && (
            <>
              <MenuItem padding={2} onClick={handleToggleStatus}>
                <IconWText
                  text={isActive ? 'Disable' : 'Enable'}
                  spacing="20px"
                  Icon={<SVG src={ToggleStatusIcon} width="16px" stroke="currentColor" />}
                />
              </MenuItem>
              <MenuItem padding={2} onClick={onOpen}>
                <IconWText
                  text="Delete"
                  spacing="20px"
                  color="red.normal"
                  Icon={<SBUserDeleteIcon width="15px" />}
                />
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="delete"
        header={
          <Box pb="1vh">
            <Text>Delete Super Drop Profile</Text>
          </Box>
        }
        body={
          <Text fontSize="text-md" color="gray.700">
            Are You Sure About Deleting This Super Drop Profile ?
          </Text>
        }
        size="lg"
        handleConfirm={onConfirm}
      />
    </>
  );
};
