import { gql } from '@apollo/client';

export const GET_DROP_PROFILES = gql`
  query CitiesDropProfiles($retailerId: UUID) {
    citiesDropProfiles(retailerId: $retailerId) {
      groupId
      cityNames
      isPrimary
      isActive
      profiles {
        id
        name
        key
        description
        profileType
        cutoffTime
        billingSlab
        isDefault
        isPrimary
        isActive
        excludedDays
        createdAt
        cities {
          city
          cutoffTime
          billingSlab
          groupId
        }
        typeDetails {
          ... on StandardProfileDto {
            __typename
            endOfDayTime
            extraDays
          }

          ... on ExpressProfileDto {
            __typename
            drivingTimeInMins
            maximumEndOffsetInMins
          }

          ... on CustomProfileDto {
            __typename
            leadTimeInHours
            timeSlotsDurationInDays
            timeSlots {
              from
              to
            }
            preScheduleSlot {
              from
              to
            }
          }
        }
      }
    }
  }
`;

export const GET_SIMPLE_DROP_PROFILES = gql`
  query GetDropProfiles($retailerId: UUID!, $isActive: Boolean) {
    dropProfiles(retailerId: $retailerId, isActive: $isActive) {
      id
      profileType
      name
      key
      isActive
      isDefault
      typeDetails {
        ... on CustomProfileDto {
          timeSlots {
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_SUPER_DROP_PROFILE_BY_CITY = gql`
  query SuperDropProfile($city: String, $isActive: Boolean, $retailerId: UUID) {
    superDropProfile(city: $city, isActive: $isActive, retailerId: $retailerId) {
      cityNames
      profiles {
        id
        profileType
        name
        key
        isActive
        isDefault
        typeDetails {
          ... on CustomProfileDto {
            timeSlots {
              from
              to
            }
          }
        }
      }
      isActive
      isPrimary
      groupId
    }
  }
`;

export const CREATE_DROP_PROFILE = gql`
  mutation CreateDropProfile($createDropProfileInput: CreateDropProfileInput!) {
    createDropProfile(createDropProfileInput: $createDropProfileInput) {
      message
      data {
        id
      }
    }
  }
`;

export const UPDATE_DROP_PROFILE = gql`
  mutation UpdateDropProfile($updateDropProfileInput: UpdateDropProfileInput!) {
    updateDropProfile(updateDropProfileInput: $updateDropProfileInput) {
      message
      data {
        id
      }
    }
  }
`;

export const REMOVE_DROP_PROFILE = gql`
  mutation RemoveDropProfile($id: UUID!) {
    removeDropProfile(id: $id) {
      message
      success
    }
  }
`;

export const REMOVER_SUPER_DROP_PROFILE = gql`
  mutation RemoveSuperDropProfile($removeSuperDropProfileId: UUID!) {
    removeSuperDropProfile(id: $removeSuperDropProfileId) {
      message
    }
  }
`;

export const MARK_AS_PRIMARY_QUERY = gql`
  mutation MarkAsPrimary($markPrimaryInput: MarkPrimaryInput!) {
    markAsPrimary(markPrimaryInput: $markPrimaryInput) {
      message
    }
  }
`;
export const TOGGLE_PROFILE_STATUS_QUERY = gql`
  mutation UpdateDropProfile($updateDropProfileInput: UpdateDropProfileInput!) {
    updateDropProfile(updateDropProfileInput: $updateDropProfileInput) {
      message
    }
  }
`;

export const UPDATE_SUPER_DROP_PROFILE = gql`
  mutation UpdateSuperDropProfiles($updateSuperDropProfilesInput: UpdateSuperDropProfilesInput!) {
    updateSuperDropProfiles(updateSuperDropProfilesInput: $updateSuperDropProfilesInput) {
      message
    }
  }
`;

export const MARK_SUPER_PROFILE_AS_PRIMARY_QUERY = gql`
  mutation MarkSuperDropProfilePrimary($markPrimaryInput: MarkPrimaryInput!) {
    markSuperDropProfilePrimary(markPrimaryInput: $markPrimaryInput) {
      message
    }
  }
`;

export const UPDATE_SUPER_DROP_PROFILE_CITIES = gql`
  mutation UpdateSuperDropProfileCities(
    $updateSuperDropProfileInput: UpdateSuperDropProfileInput!
  ) {
    updateSuperDropProfileCities(updateSuperDropProfileInput: $updateSuperDropProfileInput) {
      message
    }
  }
`;
