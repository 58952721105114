import { gql } from '@apollo/client';

export const CORE_LOCATION_DATA = gql`
  fragment CoreLocationData on LocationDto {
    id
    country
    city
    addressLine1
    addressLine2
    addressType
    area
    street
    building
    landMark
    coordinates {
      longitude
      latitude
    }
    entityId
    entityType
    isDefault
    isVerified
    dispatchZone
    dispatchCity
  }
`;

export const LIGHT_JOB_DATA = gql`
  fragment LightJobData on RouteDTO {
    id
    updatedAt
    createdAt
    status
    name
    nextStopSequence
    optimizerStatus
    reference
    label
    driver {
      user {
        name
        phone
      }
    }
    extraProps {
      stopsCount
      expectedDurationInMinutes
      ordersCount
      remainingStopsCount
      startingLocation {
        street
        addressLine1
        city
        coordinates {
          latitude
          longitude
        }
      }

      nextStopLocation {
        street
        addressLine1
        city
        coordinates {
          longitude
          latitude
        }
      }
    }
  }
`;

export const CORE_JOB_DATA = gql`
  ${CORE_LOCATION_DATA}
  ${LIGHT_JOB_DATA}
  fragment CoreJobData on RouteDTO {
    ...LightJobData
    sequenceStops {
      name
      updatedAt
      totalCount
      sequence
      completedCount
      failedCount
      locationType
      notVisitedCount
      routeId
      label
      status
      isMultiple
      containsExchangeOrder
      location {
        ...CoreLocationData
      }
      pickupOrders {
        id
        packageCount
        swftboxTracking
        paymentAmount
        groupType
        currency
        dropProfile {
          name
        }
        internationalDetails {
          isAssigned
        }
        orderFlags {
          id
          flagType
          flagReason
          status
        }
        reference
        status
        isReverse
        timing {
          slaStart
          slaEnd
          expectedPickupDateStart
          expectedPickupDateEnd
          expectedDropDateStart
          expectedDropDateEnd
          actualPickupDateStart
          actualPickupDateEnd
          actualDropDateStart
          actualDropDateEnd
        }
        to {
          ...CoreLocationData
        }
        items {
          sku
        }
        from {
          ...CoreLocationData
        }
        customer {
          phone
          name
          id
          email
        }
        retailer {
          phone
          name
          id
          logo
          procedures {
            images
            videos
            description
            id
            type
          }
        }
        state
        allowCustomerProofSignature
        shouldLeaveAtTheDoor
        requireProofOfId
        requireOtpOnDelivery
        requireDeliveryNote
        paymentMode
        arrivingShortly
        isInternational
        isRemote
        isOutOfZone
        comments {
          id
          userId
          orderId
          type
          comment
          attachments
          user {
            id
            name
          }
          userType
          createdAt
        }
      }
      coordinates {
        latitude
        longitude
      }
      dropOrders {
        id
        packageCount
        paymentAmount
        swftboxTracking
        groupType
        currency
        dropProfile {
          name
        }
        internationalDetails {
          isAssigned
        }
        orderFlags {
          id
          flagType
          flagReason
          status
        }
        reference
        status
        isReverse
        isInternational
        isRemote
        isOutOfZone
        timing {
          slaStart
          slaEnd
          expectedPickupDateStart
          expectedPickupDateEnd
          expectedDropDateStart
          expectedDropDateEnd
          actualPickupDateStart
          actualPickupDateEnd
          actualDropDateStart
          actualDropDateEnd
        }
        to {
          ...CoreLocationData
        }
        from {
          ...CoreLocationData
        }
        customer {
          phone
          name
          id
          email
        }
        retailer {
          phone
          name
          id
          logo
          procedures {
            images
            videos
            description
            id
            type
          }
        }
        state
        allowCustomerProofSignature
        shouldLeaveAtTheDoor
        requireProofOfId
        requireOtpOnDelivery
        requireDeliveryNote
        paymentMode
        arrivingShortly
        comments {
          id
          userId
          orderId
          type
          comment
          attachments
          user {
            id
            name
          }
          userType
          createdAt
        }
      }
      pickedUpOrders
      droppedOrders
      bagCollection
    }
  }
`;

export const GET_JOB_QUERY = gql`
  ${CORE_JOB_DATA}
  query PaginatedRoutes($paginatedRoutesId: [ID!], $page: Int, $perPage: Int) {
    paginatedRoutes(id: $paginatedRoutesId, page: $page, perPage: $perPage) {
      data {
        ...CoreJobData
      }
      pageCount
      total
    }
  }
`;

export const FILTERED_JOBS = gql`
  ${LIGHT_JOB_DATA}
  query FilterRoutes(
    $page: Int
    $perPage: Int
    $status: [RouteStatus!]
    $orderBy: OrderSortFields
    $order: ElasticsearchOrderSort
    $dateRange: DateRangeInput
    $label: [String!]
    $search: [String!]
    $isExpress: Boolean
    $driverId: [UUID!]
  ) {
    filterRoutes(
      page: $page
      perPage: $perPage
      status: $status
      orderBy: $orderBy
      order: $order
      dateRange: $dateRange
      label: $label
      search: $search
      isExpress: $isExpress
      driverId: $driverId
    ) {
      data {
        ...LightJobData
      }
      pageCount
      total
    }
  }
`;

export const ADD_PARTNER_ROUTE_MUTATION = gql`
  mutation CreatePickupRoute($createPickupRouteInput: IdsInput!) {
    createPickupRoute(createPickupRouteInput: $createPickupRouteInput) {
      message
    }
  }
`;

export const ADD_ORDERS_ROUTE_MUTATION = gql`
  ${CORE_JOB_DATA}

  mutation CreateOrdersRoute($createOrdersRouteInput: RouteCreationInput!) {
    createOrdersRoute(createOrdersRouteInput: $createOrdersRouteInput) {
      message
      data {
        ...CoreJobData
      }
    }
  }
`;

export const ADD_ORDERS_TO_EXIST_ROUTE_MUTATION = gql`
  ${CORE_JOB_DATA}
  mutation AddOrdersToRoute($addOrdersToRouteInput: AddOrdersToRouteInput!) {
    addOrdersToRoute(addOrdersToRouteInput: $addOrdersToRouteInput) {
      message
      data {
        ...CoreJobData
      }
    }
  }
`;
export const REMOVE_ORDER_FROM_JOB_MUTATION = gql`
  ${CORE_JOB_DATA}
  mutation unrouteOrder($unrouteOrderInput: UnRouteOrderInput!) {
    unrouteOrder(unrouteOrderInput: $unrouteOrderInput) {
      message
      data {
        ...CoreJobData
      }
    }
  }
`;
export const ASSIGN_JOB_MUTATION = gql`
  ${CORE_JOB_DATA}

  mutation AssignRoute($assignRouteInput: AssignRouteInput!) {
    assignRoute(assignRouteInput: $assignRouteInput) {
      message
      data {
        ...CoreJobData
      }
    }
  }
`;

export const ASSIGN_LASTMILE_JOB_MUTATION = gql`
  mutation Mutation($lastMileRouteInput: CreateLastMileRouteInput!) {
    createLastMileRoute(lastMileRouteInput: $lastMileRouteInput) {
      message
      data {
        id
        reference
      }
    }
  }
`;

export const START_JOB_MUTATION = gql`
  mutation StartRoute($id: UUID!) {
    startRoute(id: $id) {
      message
    }
  }
`;

export const FINISH_JOB_MUTATION = gql`
  mutation FinishRoute($id: UUID!) {
    finishRoute(id: $id) {
      message
    }
  }
`;

export const CANCEL_JOB_MUTATION = gql`
  mutation CancelRoute($id: UUID!) {
    cancelRoute(id: $id) {
      message
    }
  }
`;

export const EDIT_STOP_MUTATION = gql`
  mutation EditStop($editStopInput: EditStopInput!) {
    editStop(EditStopInput: $editStopInput) {
      message
    }
  }
`;

export const VISIT_STOP_MUTATION = gql`
  mutation VisitStop($visitStopInput: VisitStopInput!) {
    visitStop(visitStopInput: $visitStopInput) {
      message
    }
  }
`;

export const STOP_FAILED_MUTATION = gql`
  mutation StopFailed($stopFailedInput: StopFailedInput!) {
    stopFailed(stopFailedInput: $stopFailedInput) {
      message
    }
  }
`;

export const WAREHOUSE_DROP_MUTATION = gql`
  mutation WarehouseDrop($receiveOrdersInput: ReceiveOrdersInput!) {
    receiveOrders(ReceiveOrdersInput: $receiveOrdersInput) {
      message
    }
  }
`;

export const RETAILER_DROP_MUTATION = gql`
  mutation RetailerDrop($retailerDropInput: RetailerDropInput!) {
    dropAtRetailer(retailerDropInput: $retailerDropInput) {
      message
    }
  }
`;

export const CUSTOMER_DROP_MUTATION = gql`
  mutation CustomerDrop($customerDropInput: CustomerDropInput!) {
    dropAtCustomer(customerDropInput: $customerDropInput) {
      message
    }
  }
`;

export const PICKUP_MUTATION = gql`
  mutation Pickup($confirmPickupInput: ConfirmPickupInput!) {
    confirmPickup(confirmPickupInput: $confirmPickupInput) {
      message
    }
  }
`;

export const REORDER_ROUTE_MUTATION = gql`
  mutation ReorderRouteStops($reorderRouteStopsInput: ReorderRouteStopsInput!) {
    reorderRouteStops(reorderRouteStopsInput: $reorderRouteStopsInput) {
      message
      success
    }
  }
`;

export const UNROUTE_ORDER_MUTATION = gql`
  mutation UnrouteOrder($unrouteOrderInput: UnRouteOrderInput!) {
    unrouteOrder(unrouteOrderInput: $unrouteOrderInput) {
      message
      success
    }
  }
`;

export const REMOVE_ROUTE_MUTATION = gql`
  mutation RemoveRoute($removeRouteInput: RouteIdInput!) {
    removeRoute(removeRouteInput: $removeRouteInput) {
      success
      message
    }
  }
`;

export const SYNC_ROUTES = gql`
  mutation FixRoutesElasticSync($routesReferenceInput: RoutesReferenceInput!) {
    fixRoutesElasticSync(routesReferenceInput: $routesReferenceInput) {
      success
      message
    }
  }
`;

export const OPTIMIZE_ROUTE = gql`
  mutation OptimizeRoute($optimizeRouteInput: RouteIdInput!) {
    optimizeRoute(optimizeRouteInput: $optimizeRouteInput) {
      success
      message
    }
  }
`;

export const ROUTE_TIMELINE = gql`
  query RouteTimeline($routeId: UUID!) {
    routeTimeline(routeId: $routeId) {
      routeId
      createdAt
      status
      source
      reference
      label
      nextStopSequence
      optimizerStatus
      driverId
      userId
      action
      description
      actionByUser {
        name
        roles
      }
    }
  }
`;

export const STOP_TIMELINE = gql`
  query StopTimeline($routeId: UUID!) {
    getRouteStopTimeline(routeId: $routeId) {
      id
      stopId
      status
      createdAt
      coordinates {
        latitude
        longitude
      }
      stop {
        id
        orderSwftboxTracking
        sequence
        originalSequence
        startSequence
        optimizerSequence
        status
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;
