import { Box, HStack, Text, Badge } from '@swftbox/style-guide';
import { type FC } from 'react';
import { SuperDropProfileActionButtons } from './SuperDropProfileActionsButtons';

interface SuperDropProfileHeaderProps {
  cityNames: string[];
  isPrimary: boolean;
  isActive: boolean;
  retailerId: string;
  groupId: string;
  onEdit: () => void;
}

const SuperDropProfileHeader: FC<SuperDropProfileHeaderProps> = ({
  cityNames,
  isPrimary,
  isActive,
  retailerId,
  groupId,
  onEdit,
}) => {
  return (
    <Box bg="#fbfbfc" p="2" borderRadius="md">
      <HStack spacing={2} justifyContent={'space-between'}>
        <HStack>
          {cityNames.length ? (
            cityNames.map((cityName, index) => (
              <HStack bg="#D2D2D2" py="1" px="3" borderRadius="4" w="max-content" key={index}>
                <Text fontSize="text-md" fontWeight={'bold'}>
                  {cityName}
                </Text>
              </HStack>
            ))
          ) : (
            <Text fontSize="text-md" fontWeight={'bold'}>
              Default Classic Profiles
            </Text>
          )}
        </HStack>
        <HStack justifyContent={'flex-end'} pr="1em">
          <HStack mr="2.5rem">
            {isPrimary && (
              <Badge
                p="2px 8px"
                fontWeight="medium"
                borderRadius="8px"
                minWidth={'15%'}
                textAlign={'center'}
                fontSize="text-xs"
                variant="solid"
                colorScheme="blue"
              >
                Primary
              </Badge>
            )}
            {!isActive && (
              <Badge
                p="2px 8px"
                fontWeight="medium"
                borderRadius="8px"
                textAlign={'center'}
                minWidth={'15%'}
                fontSize="text-xs"
                variant="solid"
                colorScheme="red"
              >
                Disabled
              </Badge>
            )}
          </HStack>
          {cityNames.length && (
            <SuperDropProfileActionButtons
              isPrimary={isPrimary}
              isActive={isActive}
              cities={cityNames}
              retailerId={retailerId}
              groupId={groupId}
              onEdit={onEdit}
            />
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

export default SuperDropProfileHeader;
