import {
  Badge,
  Box,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBCommentUserIcon,
  SBFileCheckIcon,
  SBMenuDotsVerticalIcon,
  Text,
  Tooltip,
} from '@swftbox/style-guide';
import { SortableList } from 'src/components/Molecules/SortableList';
import { AddressCard, SchedulingCard } from 'src/components/Organisms';
import {
  JobOrder,
  JobStatus,
  stateBadgeColor,
  useOrderModalActions,
  type SequenceStops,
  OrderFlagType,
  OrderFlagStatus,
  Job,
} from 'src/components/Particles';

import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import SignatureIcon from 'src/assets/SignatureIcon.svg';
import userYellow from 'src/assets/icons/General/userYellow.svg';
import OTPIcon from 'src/assets/icons/OTPonDelivery.svg';
import SwitchIcon from 'src/assets/icons/exchange-arrow.svg';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';
import MoneyIcon from 'src/assets/icons/orders/dollar.svg';
import StarIcon from 'src/assets/icons/star.svg';
import CommentModal from 'src/components/Pages/Orders/Components/Modal/Comment/CommentModal';
import { useMemo } from 'react';
import { handleTime } from 'src/components/Pages/Jobs/Helper';
type SequenceStopWithId = SequenceStops & { id: string };
interface props {
  stop: SequenceStopWithId;
  nextStop?: number;
  job?: Job;
  handleOpenOrderDrawer: (item: SequenceStops) => void;
  performPickup: (item: SequenceStopWithId) => Promise<void>;
  markAsVisited: (item: SequenceStopWithId) => Promise<void>;
  performDrop: (item: SequenceStopWithId) => Promise<void>;
  markAsFailed: (item: SequenceStopWithId) => Promise<void>;
  setEditSequenceStop: React.Dispatch<React.SetStateAction<SequenceStopWithId | undefined>>;
}

const handleReference = (stopSequence: SequenceStops) => {
  const { dropOrders, pickupOrders } = stopSequence;
  if (pickupOrders.length === 1) return pickupOrders[0];
  if (dropOrders.length === 1) return dropOrders[0];
  // return [];
};

export const JobRow = ({
  stop,
  job,
  nextStop,
  handleOpenOrderDrawer,
  performPickup,
  markAsVisited,
  performDrop,
  markAsFailed,
  setEditSequenceStop,
}: props) => {
  const dispatch = useOrderModalActions();

  const order = handleReference(stop);

  const { consumer, location } = useMemo(
    () =>
      !order
        ? { consumer: { name: stop.name, phone: '-' }, location: stop?.location }
        : !!stop.pickupOrders.length
        ? { consumer: order?.retailer, location: order?.from }
        : { consumer: order?.customer, location: order?.to },
    [order, stop]
  );

  const keyword = order?.isRemote
    ? 'Remote'
    : order?.internationalDetails.isAssigned || order?.isInternational
    ? 'International'
    : order?.isOutOfZone
    ? 'Out of Zone'
    : undefined;
  const zone = useMemo(
    () =>
      keyword
        ? `${keyword} - ${location?.country ?? ''}`
        : `${location?.dispatchCity ?? ''} - ${location?.dispatchZone ?? ''}`,
    [location, order]
  );
  const isStared = useMemo(
    () =>
      order?.orderFlags?.filter(
        (flag) =>
          flag.flagType === OrderFlagType.star &&
          [OrderFlagStatus.pending, OrderFlagStatus.actionTaken].includes(
            flag?.status as OrderFlagStatus
          )
      )[0],
    [order]
  );

  return (
    <Grid
      gridTemplateColumns="180px 200px 180px 280px 10px"
      gridAutoFlow="row"
      justifyContent="space-between"
      gap="3"
      color="primary.900"
      fontWeight="medium"
      fontSize="x-small"
      // mb="5"
      key={`${stop.routeId}-${stop.sequence}`}
    >
      <GridItem display="flex" alignItems="center">
        <HStack fontSize="text-sm" flexWrap="nowrap">
          <SortableList.DragHandle />
          <Box
            w="27px"
            lineHeight="27px"
            h="27px"
            color="#fff"
            bg={
              +stop.completedCount === +stop.totalCount
                ? '#01BA76'
                : nextStop === +stop.sequence && order?.arrivingShortly
                ? '#63C3EC'
                : 'gray.900'
            }
            className={clsx({
              spot:
                stop?.nextStopSequence === +stop.sequence &&
                stop.status === JobStatus.STARTED &&
                order?.arrivingShortly,
            })}
            borderRadius="50%"
            textAlign="center"
          >
            {stop.sequence}
          </Box>
          <Box fontSize="text-sm" lineHeight="1.2">
            <HStack>
              {' '}
              <Text>{stop?.label.split(' ').at(0)} </Text>{' '}
              {!!isStared && (
                <Box>
                  <SVG
                    src={StarIcon}
                    stroke="black"
                    fill="#ffe500"
                    strokeWidth="25"
                    width="15"
                    style={{ marginLeft: '-2px' }}
                  />
                </Box>
              )}
            </HStack>
            <Text>{stop?.label.split(' ').at(1)} </Text>
            <HStack spacing="1">
              {order?.allowCustomerProofSignature && (
                <Tooltip hasArrow label="Signature Required">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={SignatureIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireOtpOnDelivery && (
                <Tooltip hasArrow label="OTP on delivery">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={OTPIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.shouldLeaveAtTheDoor && (
                <Tooltip label="Leave at the door" hasArrow>
                  <Box borderRadius="4" h="25px">
                    <SVG src={LeaveAtTheDoorIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireProofOfId && (
                <Tooltip hasArrow label="Proof of id">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SBCommentUserIcon width="12px" />
                  </Box>
                </Tooltip>
              )}
              {order?.requireDeliveryNote && (
                <Tooltip hasArrow label="Delivery note">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SBFileCheckIcon width="12px" />
                  </Box>
                </Tooltip>
              )}
              {order?.paymentMode === 'paymentOnDelivery' && (
                <Tooltip hasArrow label="Payment on delivery required">
                  <Box borderRadius="4" w="max-content" h="25px">
                    <SVG src={MoneyIcon} width="12px" height="24px" fill="currentColor" />
                  </Box>
                </Tooltip>
              )}
            </HStack>
          </Box>
        </HStack>
      </GridItem>
      <GridItem display="flex" alignItems="center">
        {' '}
        <Box fontSize="text-sm">
          <>
            <Text fontWeight="semibold">{order?.reference}</Text>
            <HStack>
              <Text fontWeight="semibold" color="gray.500">
                {order?.swftboxTracking ?? 'Multiple orders'}
              </Text>
              {!!order?.comments.length && <CommentModal order={order as any} />}
            </HStack>
          </>
          <HStack height="26px" spacing="0.5">
            <Badge
              py="1px"
              fontWeight="medium"
              borderRadius="8px"
              width="110px"
              textAlign="center"
              fontSize="x-small"
              border="1px solid"
              borderColor={stateBadgeColor(stop.status, '', false).border ?? 'transparent'}
              bg={stateBadgeColor(stop.status, '', false).bg}
              color={stateBadgeColor(stop.status, '', false).color}
            >
              {stateBadgeColor(stop.status, '', false).text}
            </Badge>
            {stop?.containsExchangeOrder && (
              <Tooltip hasArrow label="Exchange Stop">
                <Badge
                  p="2px 5px"
                  variant="solid"
                  background="#6764EC"
                  fontWeight="medium"
                  borderRadius="8px"
                  textAlign="center"
                  fontSize="x-small"
                  border="1px solid"
                  aria-label="stops with exchange order"
                >
                  <HStack spacing="2">
                    <HStack spacing="0.5">
                      <SVG
                        src={SwitchIcon}
                        width="11px"
                        height={'14px'}
                        stroke="#fff"
                        fill="#fff"
                      />
                      <SVG src={userYellow} width="11px" height="11px" fill="#fff" />
                    </HStack>
                  </HStack>
                </Badge>
              </Tooltip>
            )}
          </HStack>
        </Box>
      </GridItem>
      <GridItem display="flex" alignItems="center">
        {handleTime({
          isCompleted: +stop.completedCount === +stop.totalCount,
          isPickup: !!stop.pickupOrders.length,
          order: order,
        }).start ||
        handleTime({
          isCompleted: +stop.completedCount === +stop.totalCount,
          isPickup: !!stop.pickupOrders.length,
          order: order,
        }).end ? (
          <SchedulingCard
            clickable={false}
            start={
              handleTime({
                isCompleted: +stop.completedCount === +stop.totalCount,
                isPickup: !!stop.pickupOrders.length,
                order: order,
              }).start
            }
            end={
              handleTime({
                isCompleted: +stop.completedCount === +stop.totalCount,
                isPickup: !!stop.pickupOrders.length,
                order: order,
              }).end
            }
            slaEnd={order?.timing.slaEnd}
            slaStart={order?.timing.slaStart}
          />
        ) : (
          <Text>-</Text>
        )}
      </GridItem>
      <GridItem display="flex" alignItems="center">
        <AddressCard
          zone={zone}
          isVerified={location?.isVerified}
          name={(order?.isReverse ? location?.name : consumer?.name) || '-'}
          phone={location?.phone ?? (consumer?.phone as any)}
          address={`${location?.city} - ${location?.addressLine1} ${location?.addressLine2 ?? ''}`}
          location={location}
          editable={!order?.isReverse}
          showHistory
        />
      </GridItem>
      <GridItem display="flex" alignItems="center" justifyContent="end" px="5px">
        <Menu>
          <MenuButton as="button">
            <SBMenuDotsVerticalIcon width="15px" />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                {
                  order
                    ? dispatch({ type: 'OPEN_ORDER_DETAILS', payload: order as any })
                    : handleOpenOrderDrawer(stop);
                }
              }}
              fontSize="text-sm"
              color="gray.500"
            >
              {order ? 'Order details' : 'View orders'}
            </MenuItem>
            {stop.notVisitedCount > 0 && job?.status === JobStatus.STARTED && (
              <MenuItem
                onClick={() => {
                  void markAsVisited(stop);
                }}
                fontSize="text-sm"
                color="gray.500"
              >
                Mark as visited
              </MenuItem>
            )}
            {stop.pickedUpOrders.length < stop.pickupOrders.length &&
              job?.status === JobStatus.STARTED && (
                <MenuItem
                  onClick={() => {
                    void performPickup(stop);
                  }}
                  fontSize="text-sm"
                  color="gray.500"
                >
                  Pickup orders
                </MenuItem>
              )}
            {stop.droppedOrders.length < stop.dropOrders.length &&
              job?.status === JobStatus.STARTED && (
                <MenuItem
                  onClick={() => {
                    void performDrop(stop);
                  }}
                  fontSize="text-sm"
                  color="gray.500"
                >
                  Drop orders
                </MenuItem>
              )}
            {stop.droppedOrders.length + stop.pickedUpOrders.length < stop.totalCount &&
              job?.status === JobStatus.STARTED && (
                <MenuItem
                  onClick={() => {
                    void markAsFailed(stop);
                  }}
                  fontSize="text-sm"
                  color="gray.500"
                >
                  Mark as failed
                </MenuItem>
              )}
            {job?.status === JobStatus.STARTED && (
              <MenuItem
                onClick={() => {
                  setEditSequenceStop(stop);
                }}
                fontSize="text-sm"
                color="gray.500"
              >
                Edit Stop
              </MenuItem>
            )}
            {!!order?.retailer.procedures?.length && (
              <MenuItem
                onClick={() => {
                  dispatch({ type: 'OPEN_PROCEDURES', payload: order as any });
                }}
                fontSize="text-sm"
                color="gray.500"
              >
                View procedures
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </GridItem>
    </Grid>
  );
};
