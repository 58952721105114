import { Comment } from '../../comments';
import { type PaymentType } from '../../payments';
import { type Address } from '../../SharedTypes';

interface Customer {
  id: string;
  name: string;
  phone: string;
  email?: string;
}
export interface Procedure {
  id: string;
  retailerId: string;
  type: string;
  description: string;
  images: string[];
  videos: string[];
}

interface Retailer {
  id: string;
  name: string;
  phone: string;
  logo?: string;
  procedures?: Procedure[];
}
export interface Coordinates {
  latitude: number;
  longitude: number;
}

export enum OptimizerStatus {
  pending = 'pending',
  requested = 'requested',
  completed = 'completed',
  failed = 'failed',
}

export interface JobOrder {
  id: string;
  reference: string;
  packageCount?: number;
  paymentAmount?: number;
  groupType?: string;
  currency?: string;
  dropProfile?: {
    name?: string;
  };
  internationalDetails: {
    isAssigned?: string;
  };
  orderFlags?: {
    id?: string;
    flagType?: string;
    flagReason?: string;
    status?: string;
  }[];
  isReverse: boolean;
  swftboxTracking: string;
  timing: {
    expectedPickupDateStart: string | null;
    expectedPickupDateEnd: string | null;
    expectedDropDateStart: string | null;
    expectedDropDateEnd: string | null;
    slaStart: string | null;
    slaEnd: string | null;
    actualPickupDateStart: string | null;
    actualPickupDateEnd: string | null;
    actualDropDateStart: string | null;
    actualDropDateEnd: string | null;
  };
  to: Address & {
    name: string;
  };
  items: Array<{
    sku: string;
  }>;
  from: Address & {
    name: string;
  };
  state: string;
  status: string;
  retailer: Retailer;
  customer: Customer;
  allowCustomerProofSignature?: Boolean;
  shouldLeaveAtTheDoor?: Boolean;
  requireProofOfId?: Boolean;
  requireOtpOnDelivery?: Boolean;
  requireDeliveryNote?: Boolean;
  paymentMode?: string;
  arrivingShortly?: Boolean;
  comments: Comment[];
  isRemote?: Boolean;
  isInternational?: Boolean;
  isOutOfZone?: Boolean;
}
export interface SequenceStops {
  updatedAt: Date | number;
  name: string;
  totalCount: number;
  sequence: number;
  completedCount: number;
  failedCount: number;
  locationType: string;
  notVisitedCount: number;
  routeId: string;
  location: Address;
  label: string;
  status: string;
  isMultiple: boolean;
  isReverse: boolean;
  coordinates: {
    longitude: number;
    latitude: number;
  };
  pickupOrders: JobOrder[];
  dropOrders: JobOrder[];
  pickedUpOrders: string[];
  droppedOrders: string[];
  bagCollection: number | null;
  containsExchangeOrder: boolean;
  nextStopSequence?: number;
}

export interface Job {
  id: string;
  updatedAt: Date | number;
  reference: string;
  sequenceStops: SequenceStops[];
  createdAt: string;
  status: JobStatus;
  name: string;
  label: string;
  nextStopSequence: number;
  optimizerStatus: OptimizerStatus | null;
  operator: {
    name: string;
  };
  driver: {
    user: {
      name: string;
      phone: string;
    };
  };
  extraProps: {
    stopsCount: number;
    expectedDurationInMinutes: number;
    ordersCount: number;
    remainingStopsCount: number;
    startingLocation: {
      street: string;
      addressLine1: string;
      coordinates: Coordinates;
    };
    nextStopLocation?: {
      addressLine1: string;
      coordinates: Coordinates;
    };
  };
  allowCustomerProofSignature?: Boolean;
  shouldLeaveAtTheDoor?: Boolean;
  requireProofOfId?: Boolean;
  requireOtpOnDelivery?: Boolean;
  requireDeliveryNote?: Boolean;
  paymentMode?: string;
  arrivingShortly?: Boolean;
  comments: Comment[];
}

export interface StopTimeline {
  id: string;
  stopId: string;
  status: string;
  createdAt: string;
  coordinates?: Coordinates;
  stop: {
    id: string;
    orderSwftboxTracking: string;
    status: StopStatus;
    sequence: number;
    optimizerSequence?: number | null;
    startSequence?: number | null;
    originalSequence?: number | null;
    coordinates: Coordinates;
  };
}

export interface AddLocationInput {
  country: string;
  city: string;
  countryCode: string;
  addressLine1: string;
  addressLine2?: string;
  addressType: string;
  isDefault: boolean;
  area?: string;
  building?: string;
  street?: string;
  landMark?: string;
  entityId: string;
  entityType: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

export interface UpdateLocationInput {
  id: string;
  country: string;
  city: string;
  countryCode: string;
  addressLine1: string;
  addressLine2?: string;
  addressType?: string;
  isDefault: boolean;
  area?: string;
  building?: string;
  street?: string;
  landMark?: string;
  entityId: string;
  entityType: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

export interface DeleteLocationInput {
  entityType?: string;
  entityId?: string;
  id: string;
}

export interface AssignJobInput {
  driverId: string;
  routeId: string;
}

export interface ConfirmPickupInput {
  routeId: string;
  sequence: number;
  ordersSwftboxTracking: string[];
}

export interface RetailerDropInput extends ConfirmPickupInput {}

export interface StopFailedInput extends ConfirmPickupInput {
  isMissed?: boolean;
}

export interface CustomerDropInput extends ConfirmPickupInput {
  paymentType?: PaymentType;
  receipt?: File | string;
  collectedBags?: number;
}

export interface EditStopInput {
  routeId: string;
  sequence: number;
  status: StopStatus;
}

export interface VisitStopInput {
  routeId: string;
  sequence: number;
}

export interface ReorderStopsInput {
  newSequence: number[];
  routeId?: string;
}

export interface UnrouteOrderInput {
  routeId: string;
  swftboxTracking: string;
}

export interface RemoveRouteInput {
  routeId: string;
}

export enum JobStatus {
  NOT_ASSIGNED = 'notAssigned',
  ASSIGNED = 'assigned',
  STARTED = 'started',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum StopStatus {
  VISITED = 'visited',
  PENDING = 'pending',
  NOT_VISITED = 'notVisited',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
