import {
  Box,
  HStack,
  IconWText,
  Menu,
  MenuButton,
  MenuList,
  Text,
  toast,
  Tooltip,
} from '@swftbox/style-guide';
import { useCallback, useEffect, useState } from 'react';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import Svg from 'react-inlinesvg';
import MoneyIcon from 'src/assets/icons/orders/dollar.svg';
import JobIcon from 'src/assets/icons/orders/job.svg';
import AttachmentIcon from 'src/assets/icons/Editor/attachment-01.svg';
import { AssignDriver, RevealSOPs } from 'src/components/Organisms';
import { EditOrderRemarks } from 'src/components/Pages/Fullfillment/Components/Modal/EditOrder';
import { JobDetails } from 'src/components/Pages/Jobs/Components/JobDetails';
import {
  type Order,
  useAssignOrder,
  useFlagOrder,
  OrderFlagType,
  OrderFlagStatus,
  useUpdateOrderFlag,
  type OrderFlag,
  ProcedureType,
} from 'src/components/Particles';
import { AttachmentsModal } from '../Modal/Attachments';
import AddCommentModal from '../Modal/Comment/AddCommentModa';
import CommentModal from '../Modal/Comment/CommentModal';
import { RescheduleModal } from '../Modal/Reschedule';
import FlagIcon from 'src/assets/icons/orders/flag.svg';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';
import B2bIcon from 'src/assets/icons/b2b.svg';
import OversizedIcon from 'src/assets/icons/heavy.svg';
import { ConfirmFlagModal } from '../Modal/ConfirmFlag';
import InternationalIcon from 'src/assets/icons/international 2.svg';
import RemoteIcon from 'src/assets/icons/remote.svg';

interface DispatchPlanProps {
  order: Order;
}

const FlagsOptions = [
  'Incorrect address',
  'Incorrect phone number',
  'Customer refused',
  'Update payment type',
  'Missing documents/items',
  'Out of zone',
];

export const DispatchPlan = ({ order }: DispatchPlanProps) => {
  const [flag, setFlag] = useState<OrderFlag | null>(null);

  useEffect(() => {
    setFlag(
      order.orderFlags
        ? order.orderFlags.reduce((oldest: OrderFlag | null, current: OrderFlag) => {
            if (
              [OrderFlagType.cs, OrderFlagType.merchant].includes(current.flagType) &&
              current.status !== OrderFlagStatus.resolved &&
              (!oldest || new Date(current.createdAt) < new Date(oldest.createdAt))
            ) {
              return current;
            }
            return oldest;
          }, null)
        : null
    );
  }, [order]);

  const { assignOrder } = useAssignOrder();

  const { flagOrder: createFlag, loading: createFlagLoading } = useFlagOrder();
  const { updateOrderFlag: updateFlag, loading: updateFlagLoading } = useUpdateOrderFlag();

  const onChooseFlag = (flagReason?: string) => {
    if (createFlagLoading || updateFlagLoading) return;
    if (flag) {
      updateOrderFlag(flagReason);
    } else {
      createOrderFlag(flagReason);
    }
  };

  function createOrderFlag(flagReason?: string) {
    void createFlag({
      payload: {
        orderFlagInput: {
          orderId: order.id,
          flagType: OrderFlagType.merchant,
          flagReason,
        },
      },
      onCompleted: ({ createOrderFlag }) => {
        if (createOrderFlag.message) {
          setFlag(createOrderFlag.data);
          toast.success(createOrderFlag.message);
        }
      },
    });
  }

  function updateOrderFlag(flagReason?: string) {
    const payload = flagReason
      ? {
          flagReason,
          flagType: OrderFlagType.merchant,
          status: OrderFlagStatus.pending,
        }
      : {
          flagType: OrderFlagType.cs,
          status: OrderFlagStatus.resolved,
        };

    void updateFlag({
      payload: {
        updateOrderFlagInput: {
          id: flag?.id ?? '',
          ...payload,
        },
      },
      onCompleted: ({ updateOrderFlag }) => {
        if (updateOrderFlag.message) {
          setFlag(updateOrderFlag.data);
          toast.success(updateOrderFlag.message);
        }
      },
    });
  }

  const onDriverAssignment = useCallback(
    async (driverId: string, orderId?: string) => {
      try {
        if (!orderId) return false;
        const response = await assignOrder({
          payload: {
            orderIds: [orderId],
            driverId,
          },
        });
        toast.success(response.data?.createQuickRoute.message);
        return true;
      } catch {
        return false;
      }
    },
    [assignOrder]
  );

  const oversizedComments = order.comments?.filter((comment) => comment.type === 'OVERSIZED') || [];
  const oversizedComment =
    oversizedComments.length > 0 ? oversizedComments[0].comment : 'Oversized';

  return (
    <>
      <HStack>
        <Menu>
          <Tooltip label={flag?.flagReason} hasArrow>
            <MenuButton
              aria-label="order flag"
              color="primary.900"
              bg="#EFEFEF"
              h="24px"
              borderRadius="4px"
              boxShadow="none"
              minW="2.5rem"
              _hover={{ backgroundColor: '#9090903b' }}
            >
              <Svg
                src={FlagIcon}
                width="15px"
                fill={
                  !flag?.flagType || flag.status === OrderFlagStatus.resolved
                    ? 'none'
                    : flag.flagType === OrderFlagType.merchant
                    ? 'red'
                    : '#32C6F1'
                }
                stroke={
                  !flag?.flagType || flag.status === OrderFlagStatus.resolved
                    ? 'currentColor'
                    : flag.flagType === OrderFlagType.merchant
                    ? 'red'
                    : '#32C6F1'
                }
                style={{ margin: 'auto' }}
              />
            </MenuButton>
          </Tooltip>

          <MenuList
            borderRadius="8px"
            bg="#fff"
            border="1px solid #EAECF8"
            padding="12px"
            zIndex={1}
          >
            {FlagsOptions.map((option) => (
              <ConfirmFlagModal
                key={option}
                option={option}
                onChooseFlag={onChooseFlag}
                type="flag"
                order={order}
              />
            ))}
            {flag?.flagType && (
              <ConfirmFlagModal
                option="Clear Flag"
                onChooseFlag={onChooseFlag}
                type="clear"
                order={order}
              />
            )}
          </MenuList>
        </Menu>

        <RescheduleModal order={order} />
      </HStack>
      <HStack>
        <Box bg="#EFEFEF" borderRadius="4" w="max-content">
          <HStack spacing="0">
            <CommentModal order={order} />
            <Text py="1">|</Text>
            <AddCommentModal order={order} />
          </HStack>
        </Box>
        {!order.routeId && order.internationalDetails?.carrierName ? (
          <Box>
            <Box
              bg="#EFEFEF"
              opacity="0.5"
              minW="110px"
              minH="24px"
              p="1"
              borderRadius="4"
              my="2"
              mr="auto"
            >
              <HStack w="100%" justifyContent="space-between">
                <Svg src={JobIcon} />
                <Text>{order.internationalDetails?.carrierName}</Text>
                <div />
              </HStack>
            </Box>
          </Box>
        ) : order.routeId ? (
          <JobDetails
            id={order.routeId}
            OpenButton={
              <Box
                bg="#EFEFEF"
                minW="110px"
                minH="24px"
                p="1"
                borderRadius="4"
                my="2"
                mr="auto"
                color="primary.900"
              >
                <Tooltip label={order.driverPhone}>
                  <HStack w="100%" justifyContent="space-between">
                    <Svg src={JobIcon} />
                    <Text>{order.driverName || '-'}</Text>
                    <div />
                  </HStack>
                </Tooltip>
              </Box>
            }
          />
        ) : (
          <AssignDriver entityName="order" onAssign={onDriverAssignment} id={order.id}>
            <Box>
              <Tooltip label="Assign Driver">
                <Box
                  bg="#EFEFEF"
                  opacity="0.5"
                  minW="110px"
                  minH="24px"
                  p="1"
                  borderRadius="4"
                  my="2"
                  mr="auto"
                >
                  <HStack w="100%" justifyContent="space-between">
                    <Svg src={JobIcon} />
                    <Text>{order.driverName || '-'}</Text>
                    <div />
                  </HStack>
                </Box>
              </Tooltip>
            </Box>
          </AssignDriver>
        )}
      </HStack>
      <HStack>
        {!!order.paymentAmount && (
          <Tooltip label="Collect on delivery" hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <IconWText
                text={order.paymentAmount ? `${order.paymentAmount}` : '0'}
                Icon={<Svg src={MoneyIcon} fill="currentColor" />}
                color="currentColor"
                pr="4px"
              />
              {order.payment?.status === 'APPROVED' && (
                <Svg src={VerifyIcon} width="14px" fill="primary.900" />
              )}
            </Box>
          </Tooltip>
        )}
        {order?.shouldLeaveAtTheDoor && (
          <Tooltip label="Leave at the door" hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={LeaveAtTheDoorIcon} width="14px" height={'17px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.b2bHandling && (
          <AttachmentsModal
            size="sm"
            title="B2B invoice"
            attachments={order.b2bInvoice ?? []}
            attachmentsURLs={order.b2bInvoice ?? []}
            button={
              <Tooltip label="B2B Invoice" hasArrow>
                <Box
                  bg="#EFEFEF"
                  py="1"
                  px="3"
                  borderRadius="4"
                  display="flex"
                  w="max-content"
                  color="primary.900"
                >
                  <Svg src={B2bIcon} width="20px" height={'20px'} />
                </Box>
              </Tooltip>
            }
          />
        )}

        {!!order.notes && <EditOrderRemarks orderId={order.id} notes={order.notes} />}

        {!!order.isRemote && (
          <Tooltip label={'Remote'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={RemoteIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.isInternational && (
          <Tooltip label={'International'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={InternationalIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.oversized && (
          <Tooltip label={oversizedComment} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={OversizedIcon} width="14px" height={'17px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.attachments?.length && (
          <Box cursor="pointer">
            <AttachmentsModal
              attachments={order.attachments || []}
              attachmentsURLs={order.attachments || []}
              button={
                <Tooltip label="view Attachment" hasArrow>
                  <Box
                    bg="#EFEFEF"
                    px="3"
                    borderRadius="4"
                    w="max-content"
                    h="25px"
                    color="primary.900"
                  >
                    <Svg src={AttachmentIcon} width="12px" stroke="currentColor" />
                  </Box>
                </Tooltip>
              }
            />
          </Box>
        )}
        {order.retailer.procedures?.length &&
          order.retailer.procedures.some((sop) =>
            [ProcedureType.DISPATCH, ProcedureType.DRIVER].includes(sop.type)
          ) && (
            <RevealSOPs
              SOPTypes={[ProcedureType.DISPATCH, ProcedureType.DRIVER]}
              procedures={order.retailer.procedures}
            />
          )}
      </HStack>
    </>
  );
};
