import { HStack, Table, TableContainer, Text, Th, Thead, Tr } from '@swftbox/style-guide';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollToTopButton } from 'src/components/Atom';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';

import {
  useFilterOrdersQuery,
  useOrdersFilters,
  useOrdersFiltersActions,
  useSearch,
  type Order,
} from 'src/components/Particles';
import { useOrdersMilestone } from 'src/components/Particles/resolvers/orders/getOrderMileStones';
import { SelectionOptions } from './SelectionOptions';
import TableBody from './TableBody';
import { OrderCheckboxAll } from './TableCells';
import {
  handleORClauseFilter,
  handleInfiniteScrollInTable,
  infiniteScroll,
  ordersQueryInitialVariables,
} from './helper';

const getOrderIds = (data: Order[]) => data?.map((order) => order.id);

function OrdersTable() {
  const tableRef = useRef(null);
  const [displayedRows, setDisplayedRows] = useState(infiniteScroll.displayedRowsCount);
  const { searchText } = useSearch();
  const FilterAction = useOrdersFiltersActions();
  const {
    filters: {
      page,
      stateStageFilter,
      status,
      sortFilter,
      stopStatus,
      validationStatus,
      failedAttemptCount,
      statusId,
      flagType,
    },
    organizedFilters,
  } = useOrdersFilters();

  const { getMileStones } = useOrdersMilestone({});
  const handleGetMileStonesAfterOrders = useCallback(
    async (orders: Order[]) =>
      orders.length
        ? getMileStones({ variables: { orderIds: { ids: getOrderIds(orders) } } })
        : null,
    [getMileStones]
  );
  const { getOrders, orders, totalOrders, loading, called } = useFilterOrdersQuery(
    ordersQueryInitialVariables,
    handleGetMileStonesAfterOrders
  );

  const dropProfileOrStatusFilter =
    organizedFilters?.or?.dropProfileKey?.length ?? statusId?.length;

  useEffect(() => {
    const { isInternational, isRemote, ...filtersWithoutInternational } = organizedFilters;

    const filters = {
      page,
      flagType: flagType?.length ? flagType : undefined,
      failedAttemptCount,
      ...(searchText ? { search: searchText } : {}),
      stopStatus: stopStatus?.length ? stopStatus : undefined,
      ...stateStageFilter,
      ...sortFilter,
      status: status?.length ? status : undefined,
      isInternational: !!isInternational || undefined,
      isRemote: !!isRemote || undefined,
      validationStatus: validationStatus?.length ? validationStatus : undefined,
      ...filtersWithoutInternational,
      ...(dropProfileOrStatusFilter &&
        handleORClauseFilter(
          organizedFilters.or?.dropProfileKey,
          !!isInternational,
          !!isRemote,
          statusId
        )),
      ...(!flagType?.length &&
        organizedFilters.dateFilter?.isSelected && {
          dateRange: {
            key: organizedFilters.dateFilter.key,
            from: organizedFilters.dateFilter.startDate as Date,
            to: organizedFilters.dateFilter.endDate as Date,
          },
        }),
    };
    getOrders(filters);
    setDisplayedRows(infiniteScroll.displayedRowsCount);
  }, [
    page,
    failedAttemptCount,
    searchText,
    stopStatus,
    stateStageFilter,
    sortFilter,
    validationStatus,
    status,
    flagType,
    dropProfileOrStatusFilter,
    statusId,
    organizedFilters,
    getOrders,
    setDisplayedRows,
  ]);

  const scrollHandler = useCallback(() => {
    handleInfiniteScrollInTable({ tableRef, setDisplayedRows });
  }, [setDisplayedRows, tableRef]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollHandler]);

  return (
    <>
      <SelectionOptions />
      <TableContainer
        whiteSpace="normal"
        ref={tableRef}
        pb={'orders.length < 50 && !loading' ? '450px' : 'unset'}
      >
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              <Th px="3">
                <HStack spacing="24px">
                  <OrderCheckboxAll orders={orders} />
                  <Text>Reference</Text>
                </HStack>
              </Th>
              <Th px="3">Dispatch Plan</Th>
              <Th px="3">Timings</Th>

              <Th px="3">Pickup Address</Th>
              <Th px="3">Drop Address</Th>
              <Th
                position="sticky"
                className="floating-pagination"
                height="100%"
                p={{ base: '0', '2xl': '0 20px' }}
                bg="#fbfbfc"
                right="0"
                pr="2"
                zIndex="2"
              >
                <HStack h="100%">
                  <SmallPaginate
                    totalOrders={totalOrders}
                    page={page ?? 1}
                    perPage={ordersQueryInitialVariables.perPage}
                    onPageChange={(page) => {
                      FilterAction({ type: 'ORDER_PAGE_CHANGE', payload: { page } });
                    }}
                    onSortChange={(sort) => {
                      FilterAction({ type: 'ORDER_SORT_CHANGE', payload: { sortFilter: sort } });
                    }}
                  />
                </HStack>
              </Th>
              <Th px="0" position={{ base: 'absolute', '2xl': 'sticky' }} width="50px" />
            </Tr>
          </Thead>
          <TableBody
            orders={orders}
            loading={loading}
            called={called}
            displayedRows={displayedRows}
          />
        </Table>
      </TableContainer>
      <ScrollToTopButton />
    </>
  );
}

export default OrdersTable;
