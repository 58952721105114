import { Box, TableContainer, Flex, Spinner } from '@swftbox/style-guide';
import { type FC, useState } from 'react';
import { type SuperDropPorfile, type DropProfile } from 'src/components/Particles';
import { DropProfileDetails } from './DropProfileDetails/DropProfileDetails';
import React from 'react';
import { SuperDropProfileEditForm } from './SuperDropProfile/EditSuperDropProfileForm';
import SuperDropProfileHeader from './SuperDropProfile/SuperDropProfileHeader';
import DropProfileTable from './SuperDropProfile/SuperDropProfileTable';

interface Props {
  superDropProfiles: SuperDropPorfile[];
  editDropProfile: (dropProfile: DropProfile) => void;
  loading: boolean;
  retailerId: string;
}

export const SuperDropProfilesTable: FC<Props> = ({
  superDropProfiles,
  editDropProfile,
  loading,
  retailerId,
}) => {
  const [dropProfile, setDropProfile] = useState<DropProfile | null>(null);
  const [superDropProfile, setSuperDropProfile] = useState<SuperDropPorfile | null>(null);

  const onEditDropProfile = (dropProfile: DropProfile) => {
    editDropProfile(dropProfile);
  };

  const onViewDetails = (dropProfile: DropProfile) => {
    setDropProfile(dropProfile);
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h="20vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const existingCitiesDropProfiles = superDropProfiles.reduce<string[]>((acc, dropProfile) => {
    return acc.concat(dropProfile.cityNames);
  }, []);

  return (
    <Box>
      {superDropProfile ? (
        <SuperDropProfileEditForm
          superDropProfile={superDropProfile}
          retailerId={retailerId}
          existingCitiesDropProfiles={existingCitiesDropProfiles}
          editDropProfile={onEditDropProfile}
          onClose={() => {
            setSuperDropProfile(null);
          }}
        />
      ) : dropProfile ? (
        <DropProfileDetails
          dropProfile={dropProfile}
          back={() => {
            setDropProfile(null);
          }}
        />
      ) : (
        <TableContainer whiteSpace="normal">
          {superDropProfiles?.map((superDropProfile, index) => (
            <React.Fragment key={`${superDropProfile.groupId}-${index}`}>
              <SuperDropProfileHeader
                cityNames={superDropProfile.cityNames}
                isPrimary={superDropProfile.isPrimary}
                isActive={superDropProfile.isActive}
                retailerId={retailerId}
                groupId={superDropProfile.groupId}
                onEdit={() => {
                  setSuperDropProfile(superDropProfile);
                }}
              />
              {!!superDropProfile.profiles.length && (
                <DropProfileTable
                  dropProfiles={superDropProfile.profiles}
                  groupId={superDropProfile.groupId}
                  retailerId={retailerId}
                  onEditDropProfile={onEditDropProfile}
                  onViewDetails={onViewDetails}
                />
              )}
            </React.Fragment>
          ))}
        </TableContainer>
      )}
    </Box>
  );
};
