import { useState, type FC } from 'react';
import {
  Box,
  Text,
  Input,
  Button,
  FormLabel,
  HStack,
  Stack,
  toast,
  FormControl,
  Textarea,
  IconButton,
  SBTrashIcon,
  Checkbox,
  CheckboxGroup,
  HelperText,
} from '@swftbox/style-guide';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreateDropProfileMutation,
  type DropProfile,
  type CreateDropProfileInput,
  DropProfileType,
  type UpdateDropProfileInput,
  type StandardDropProfile,
  type ExpressDropProfile,
  type CustomDropProfile,
  useUpdateDropProfileMutation,
  type TimeSlot,
  type SuperDropPorfile,
} from 'src/components/Particles';
import DropProfileCities from './DropProfileCities';
import { daysOfWeek } from '../../../../../Helper';
import SelectCities from '../SelectCities';

interface Props {
  dropProfiles: SuperDropPorfile[];
  cities?: string[];
  retailerId: string;
  onClose?: () => void;
  dropProfile?: DropProfile;
  back: () => void;
}

const getDefaultValues = (retailerId: string, dropProfile?: DropProfile) => {
  if (!dropProfile) {
    return { retailerId, excludedDays: [] };
  }

  return {
    id: dropProfile.id,
    name: dropProfile.name,
    description: dropProfile.description,
    billingSlab: dropProfile.billingSlab,
    cutoffTime: dropProfile.cutoffTime,
    standardProfile:
      dropProfile.profileType === DropProfileType.standard
        ? (dropProfile.typeDetails as StandardDropProfile)
        : null,
    expressProfile:
      dropProfile.profileType === DropProfileType.express
        ? (dropProfile.typeDetails as ExpressDropProfile)
        : null,
    customProfile:
      dropProfile.profileType === DropProfileType.custom
        ? (dropProfile.typeDetails as CustomDropProfile)
        : null,
    cities: dropProfile.cities || [],
    excludedDays: dropProfile.excludedDays || [],
  };
};

export const DropProfileForm: FC<Props> = ({
  retailerId,
  dropProfile,
  onClose,
  cities,
  dropProfiles,
}) => {
  const { handleSubmit, control, reset, getValues, watch, setValue } = useForm<
    CreateDropProfileInput | UpdateDropProfileInput
  >({
    mode: 'all',
    defaultValues: getDefaultValues(retailerId, dropProfile),
  });

  const { createDropProfile, loading: creating } = useCreateDropProfileMutation();
  const { updateDropProfile, loading: updating } = useUpdateDropProfileMutation();
  const [selectedCities, setSelectedCities] = useState<string[]>(cities ?? []);
  const [cityError, setCityError] = useState<string>('');

  const cutoffTime = watch('cutoffTime');
  const billingSlab = watch('billingSlab');

  const citiesSelected = selectedCities.length
    ? selectedCities
    : dropProfile?.cities
    ? dropProfile?.cities.map((city) => city.city)
    : [];

  const onSubmit = handleSubmit(async (data) => {
    const newCities = citiesSelected?.map((city) => {
      return {
        city,
        billingSlab: Number(billingSlab),
        cutoffTime,
        groupId: dropProfiles[0]?.groupId,
      };
    });

    if ('id' in data) {
      data.cities = newCities;
      const { data: result } = await updateDropProfile(data);
      toast.success(result?.updateDropProfile?.message);
    } else {
      if (!selectedCities.length) {
        setCityError('Please select a city to be able to proceed');
        return;
      } else {
        if (!data.cities?.length) {
          data.cities = newCities;
        }
        const { data: result } = await createDropProfile(data);
        toast.success(result?.createDropProfile?.message);
      }
    }

    reset();
    onClose?.();
  });

  const customTimeslots = watch('customProfile.timeSlots');

  const addTimeSlot = () => {
    setValue('customProfile.timeSlots', [
      ...(getValues('customProfile.timeSlots') ?? []),
      { from: '', to: '' },
    ]);
  };

  const removeTimeSlot = (indexToRemove: number) => {
    setValue(
      'customProfile.timeSlots',
      (getValues('customProfile.timeSlots') ?? []).filter((_, index) => index !== indexToRemove)
    );
  };

  const existingCitiesDropProfiles = dropProfiles.reduce<string[]>((acc, dropProfile) => {
    return acc.concat(dropProfile.cityNames);
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Box mb={5}>
        <Text fontWeight={'bold'}>
          {dropProfile ? 'Edit drop profile' : 'Create new super drop profile'}
        </Text>
      </Box>
      <Stack gap={4}>
        <SelectCities
          selectedCities={citiesSelected}
          existingCitiesDropProfile={existingCitiesDropProfiles}
          onChange={(newSelectedCities) => {
            setSelectedCities(newSelectedCities);
          }}
        />
        {cityError && !selectedCities.length && (
          <HelperText variant="error">{cityError}</HelperText>
        )}
        <HStack>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={value || ''}
                  onChange={onChange}
                  placeholder="Drop profile name"
                  required
                />
              </FormControl>
            )}
          />
          <Controller
            name="cutoffTime"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>Cutoff time</FormLabel>
                <Input type="time" value={value || ''} onChange={onChange} required />
              </FormControl>
            )}
          />
          <Controller
            name="billingSlab"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormLabel>Billing slab</FormLabel>
                <Input
                  type="number"
                  value={value ?? ''}
                  onChange={onChange}
                  placeholder="Billing slab (numeric)"
                />
              </FormControl>
            )}
          />
        </HStack>
        {(!dropProfile || dropProfile.profileType === DropProfileType.express) && (
          <HStack>
            <Controller
              name={`${dropProfile ? 'expressProfile.' : ''}drivingTimeInMins`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Driving time (min)</FormLabel>
                  <Input
                    type="number"
                    value={value ?? ''}
                    onChange={onChange}
                    required
                    placeholder="Driving time in minutes"
                  />
                </FormControl>
              )}
            />
            <Controller
              name={`${dropProfile ? 'expressProfile.' : ''}maximumEndOffsetInMins`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>Maximum end offset (min)</FormLabel>
                  <Input
                    type="number"
                    value={value ?? ''}
                    onChange={onChange}
                    required
                    placeholder="Maximum end offset time in minutes"
                  />
                </FormControl>
              )}
            />
          </HStack>
        )}
        {dropProfile?.profileType === DropProfileType.standard && (
          <HStack>
            <Controller
              name={`standardProfile.endOfDayTime`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <FormLabel>End of day time</FormLabel>
                  <Input
                    type="time"
                    value={value ?? ''}
                    onChange={onChange}
                    required
                    placeholder="End of day time"
                  />
                </FormControl>
              )}
            />
          </HStack>
        )}
        {dropProfile?.profileType === DropProfileType.custom && (
          <>
            <HStack>
              <Controller
                name={`customProfile.leadTimeInHours`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormLabel>Lead time (hr)</FormLabel>
                    <Input
                      type="number"
                      value={value ?? ''}
                      onChange={onChange}
                      placeholder="Lead time in hours"
                    />
                  </FormControl>
                )}
              />
              <Controller
                name={`customProfile.timeSlotsDurationInDays`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormLabel>Time slots duration (days)</FormLabel>
                    <Input
                      type="number"
                      value={value ?? ''}
                      onChange={onChange}
                      placeholder="Time slots duration in days"
                    />
                  </FormControl>
                )}
              />
            </HStack>
            <HStack>
              <Controller
                name={`customProfile.preScheduleSlot.from`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormLabel>Pre-schedule time slot (From)</FormLabel>
                    <Input
                      type="time"
                      value={value || ''}
                      onChange={onChange}
                      placeholder="From time"
                    />
                  </FormControl>
                )}
              />
              <Controller
                name={`customProfile.preScheduleSlot.to`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormLabel>To</FormLabel>
                    <Input
                      type="time"
                      value={value || ''}
                      onChange={onChange}
                      placeholder="To time"
                    />
                  </FormControl>
                )}
              />
            </HStack>
            <hr />
            {getValues('customProfile.timeSlots')?.map((_: TimeSlot, index: number) => (
              <HStack key={index}>
                <Controller
                  name={`customProfile.timeSlots.${index}.from`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      {index === 0 && <FormLabel>Time slots (From)</FormLabel>}
                      <Input
                        type="time"
                        value={value || ''}
                        onChange={onChange}
                        placeholder="From time"
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name={`customProfile.timeSlots.${index}.to`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      {index === 0 && <FormLabel>To</FormLabel>}
                      <Input
                        type="time"
                        value={value || ''}
                        onChange={onChange}
                        placeholder="To time"
                      />
                    </FormControl>
                  )}
                />
                <IconButton
                  onClick={() => {
                    removeTimeSlot(index);
                  }}
                  size="small"
                  variant="link"
                  color="gray.600"
                  _hover={{ opacity: '0.5', color: 'red' }}
                  aria-label="delete timeSlot"
                  icon={<SBTrashIcon width="15px" />}
                  disabled={customTimeslots?.length === 1}
                />
              </HStack>
            ))}
            <Button
              alignSelf={'flex-end'}
              width={'25%'}
              onClick={addTimeSlot}
              disabled={customTimeslots?.length === 12}
            >
              Add Time Slot
            </Button>
            <hr />
          </>
        )}
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                rows={2}
                value={value || ''}
                onChange={onChange}
                required
                placeholder="Drop profile description"
              />
            </FormControl>
          )}
        />
        <Controller
          name="excludedDays"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel>Excluded Days</FormLabel>
              <CheckboxGroup
                value={value ?? []}
                onChange={(selectedValues) => {
                  onChange(selectedValues.map(String));
                }}
              >
                <HStack spacing={6}>
                  {daysOfWeek.map((day) => (
                    <Checkbox key={day.value} value={day.value}>
                      {day.label}
                    </Checkbox>
                  ))}
                </HStack>
              </CheckboxGroup>
            </FormControl>
          )}
        />
        <Controller
          name="cities"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <DropProfileCities
                editable
                onChange={onChange}
                existingCitiesDropProfile={existingCitiesDropProfiles}
                cities={
                  value ??
                  selectedCities.map((city) => {
                    return { city, cutoffTime, billingSlab };
                  }) ??
                  []
                }
              />
            </FormControl>
          )}
        />
        <Stack spacing="3" w="100%" direction={['column', 'row']}>
          <Button variant="outline" w="100%" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" type="submit" disabled={creating || updating}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
