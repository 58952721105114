import { addDays } from 'date-fns';
import { handleAddRemoveArray, handleAddRemoveElement } from '../../helper';

import { arraysAreEqual } from 'src/components/Pages/Orders/Components/Filters/StatusFilters/helper';
import {
  type FilterAction,
  type OrdersFilters,
  type OrdersOrganizingFilters,
  type OrganizedFilterAction,
} from './types';
import { isArray } from '@apollo/client/utilities';

interface NewStatus {
  payload: {
    status?: string[];
    stopStatus?: string[];
    validationStatus?: string[];
    failedAttemptCount?: number;
    flagType?: string[];
  };
  id?: string[];
}
interface HandleStatusReturn {
  statusId?: string[];
  status?: string[];
  stopStatus?: string[];
  validationStatus?: string[];
  failedAttemptCount?: number;
  flagType?: string[];
}

const handleStatusMap = (prevStatusId: string[] = [], newStatus: NewStatus): HandleStatusReturn => {
  const {
    payload: {
      status = [],
      stopStatus = [],
      validationStatus = [],
      failedAttemptCount,
      flagType = [],
    },
    id = [],
  } = newStatus;
  return arraysAreEqual(prevStatusId, id)
    ? {
        statusId: [],
        status: [],
        stopStatus: [],
        validationStatus: [],
        failedAttemptCount: undefined,
        flagType: [],
      }
    : {
        statusId: id,
        status,
        stopStatus,
        validationStatus,
        failedAttemptCount,
        flagType,
      };
};

const filtersReducer = (state: OrdersFilters, action: FilterAction) => {
  switch (action.type) {
    case 'ORDER_PAGE_CHANGE':
      return { ...state, page: action.payload.page };
    case 'ORDER_STATE_CHANGE':
      return { ...state, status: action.payload.status };
    case 'ORDER_STATUS_MAP_CHANGE':
      return {
        ...state,
        ...handleStatusMap(state?.statusId, action),
      };
    case 'ORDER_SORT_CHANGE':
      return { ...state, sortFilter: action.payload.sortFilter ?? {} };
    case 'ORDER_ADVANCED_FILTERS_CHANGE':
      return { ...state, advancedFilters: action.payload.advancedFilters };
    default:
      return state;
  }
};
const organizedFiltersReducer = (state: OrdersOrganizingFilters, action: OrganizedFilterAction) => {
  switch (action.type) {
    case 'ORDER_DATE_CHANGE':
      return { ...state, dateFilter: action.payload.dateFilter ?? {} };
    case 'ORDERS_CITIES_CHANGE':
      return { ...state, toCity: handleAddRemoveElement(state.toCity ?? [], action.payload.city) };
    case 'ORDERS_ZONES_CHANGE':
      return {
        ...state,
        dispatchZone: handleAddRemoveElement(state.dispatchZone ?? [], action.payload.zone),
      };
    case 'ORDERS_CATEGORIES_CHANGE':
      return {
        ...state,
        partnerCategoryIds: handleAddRemoveElement(
          state.partnerCategoryIds ?? [],
          action.payload.category
        ),
      };
    case 'ORDERS_DRIVER_CHANGE':
      return {
        ...state,
        driverId: handleAddRemoveElement(state.driverId ?? [], action.payload.driver),
      };
    case 'ORDERS_RETAILER_CHANGE':
      return {
        ...state,
        retailerId: isArray(action.payload.retailerId)
          ? action.payload.retailerId
          : handleAddRemoveElement(state.retailerId ?? [], action.payload.retailerId),
      };
    case 'ORDERS_IS_INTERNATIONAL_CHANGE':
      return {
        ...state,
        isInternational: action.payload.isInternational,
      };
    case 'ORDERS_IS_REMOTE_CHANGE':
      return {
        ...state,
        isRemote: action.payload.isRemote,
      };
    case 'ORDERS_TYPES_CHANGE':
      return {
        ...state,
        or: {
          dropProfileKey: handleAddRemoveArray(state.or?.dropProfileKey ?? [], action.payload.type),
        },
      };
    case 'CLEAR_ORGANIZING_FILTERS':
      return {
        dateFilter: {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          isSelected: false,
          key: 'selection',
        },
      };
    default:
      return state;
  }
};

export const rootReducer = (state: any, action: any) => ({
  filters: filtersReducer(state.filters, action),
  organizedFilters: organizedFiltersReducer(state.organizedFilters, action),
});
