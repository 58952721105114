import { Autocomplete, Button, HStack } from '@swftbox/style-guide';
import { useEffect, useMemo, useState } from 'react';
import { InvoiceStatus, type Retailer, useRetailersQuery } from 'src/components/Particles';
import { statusFilter } from './Filter.helper';

interface UsersFilterProps {
  onFilterChange: (filterValue: InvoiceStatus[]) => void;
  setRetailersIds: (ids: string[] | null) => void;
  retailersIds?: string[] | null;
}

export function Filters(props: UsersFilterProps) {
  const { onFilterChange, setRetailersIds, retailersIds } = props;
  const [active, setActive] = useState<InvoiceStatus[]>(statusFilter[0].value);
  const { retailers, getRetailers, loading } = useRetailersQuery();

  useEffect(() => {
    getRetailers({ page: 1, perPage: 500 });
  }, [getRetailers]);

  const selectedOptions = useMemo(() => {
    if (!retailersIds || retailersIds.length === 0) return null;
    return retailersIds
      .map((val) => {
        const retailer = retailers.find((retailer: Retailer) => retailer.id === val);
        return retailer ? { label: retailer.name, value: val } : null;
      })
      .filter(Boolean);
  }, [retailersIds, retailers]);

  const options = useMemo(() => {
    return retailers?.map((retailer) => ({ label: retailer.name, value: retailer.id })) || [];
  }, [retailers]);

  return (
    <HStack p={5} w={'100%'}>
      <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
        <HStack
          border="1px solid #D0D5DD;"
          h="40px"
          lineHeight="40px"
          borderRadius="8px"
          shadow="xs"
          overflow="hidden"
          spacing="0"
        >
          {statusFilter.map((filter) => (
            <Button
              p="8px 12px"
              fontSize="x-small"
              key={filter.id}
              variant="grouped"
              bg={active === filter.value ? '#63c3ec' : 'transparent'}
              color={active === filter.value ? '#fff' : 'gray.900'}
              _focus={{ opacity: 0.85 }}
              _hover={{ opacity: 0.85 }}
              onClick={() => {
                onFilterChange(filter.value as InvoiceStatus[]);
                setActive(filter.value as InvoiceStatus[]);
              }}
            >
              {filter.label}
            </Button>
          ))}
        </HStack>
      </HStack>
      <Autocomplete
        isLoading={loading}
        isClearable
        isMulti
        label={undefined}
        className="auto-complete-multi"
        size="small"
        onChange={(option) => {
          if (!option) return;
          // @ts-expect-error it's just to ignore dummy ts warning
          setRetailersIds(option.map((opt) => opt.value));
        }}
        options={options}
        placeholder="Select retailer"
        value={selectedOptions}
      />
    </HStack>
  );
}
