import {
  Autocomplete,
  Box,
  Grid,
  GridItem,
  HStack,
  SBModal,
  Text,
  toast,
  useDisclosure,
} from '@swftbox/style-guide';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  type Order,
  DropProfileType,
  type DropProfile,
  type CustomDropProfile,
  useSuperDropProfileByCityQuery,
} from 'src/components/Particles';
import {
  type DropDownItem,
  getDateSlots,
  getSingleTimeSlots,
  getNearestNext30Min,
} from '../../helper';
import { useUpdateOrderDropProfile } from 'src/components/Particles/resolvers/orders/updateOrderDropProfile.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateOrderDropProfileSchema } from './updateDropProfile.schema';
import { format } from 'date-fns';
import Svg from 'react-inlinesvg';
import ColoredRocketIcon from 'src/assets/icons/colored-rocket.svg';

interface UpdateDropProfileProps {
  order: Order;
}

interface UpdateDropProfileInput {
  id: string;
  dropProfileId: string;
  date: string;
  time: string;
}

export function UpdateDropProfile({ order }: UpdateDropProfileProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedProfileType, setSelectedProfileType] = useState<DropProfileType>();
  const [doubleTimeSlots, setDoubleTimeSlots] = useState<DropDownItem[]>([]);

  const isCustomProfileType = selectedProfileType === DropProfileType.custom;
  const isStandardProfileType = selectedProfileType === DropProfileType.standard;

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdateDropProfileInput>({
    mode: 'all',
    resolver: yupResolver(UpdateOrderDropProfileSchema(!isStandardProfileType)),
    defaultValues: {
      id: order?.id,
    },
  });

  const dateSlots: DropDownItem[] = useMemo(() => getDateSlots(), []);
  const singleTimeSlots = useMemo(() => getSingleTimeSlots(), []);

  const date = watch('date');

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isDropLabel = isCustomProfileType || isStandardProfileType;

  const { superDropProfile, getSuperDropProfile } = useSuperDropProfileByCityQuery({
    retailerId: order.retailer.id,
    isActive: true,
    city: order.isReverse ? order.from.dispatchCity : order.to.dispatchCity,
  });

  useEffect(() => {
    if (isOpen) void getSuperDropProfile();
  }, [isOpen, getSuperDropProfile]);

  const { updateOrderDropProfile } = useUpdateOrderDropProfile();

  const handleConfirm = (data: UpdateDropProfileInput) => {
    const { date, time, ...rest } = data;
    const [startTime, endTime] = time?.split('-') ?? [];

    void updateOrderDropProfile({
      payload: {
        ...rest,
        scheduledPickupSlot:
          !isDropLabel && time
            ? {
                date: date ?? format(new Date(), 'yyyy-MM-dd'),
                from: time,
              }
            : undefined,
        scheduledCustomerSlot:
          isDropLabel && startTime
            ? {
                date: date ?? format(new Date(), 'yyyy-MM-dd'),
                from: startTime,
                to: endTime ?? startTime, // dummy to handle required field in backend
              }
            : undefined,
      },
      onCompleted: ({ updateOrderDropProfile }) => {
        if (updateOrderDropProfile.message) {
          toast.success(updateOrderDropProfile.message);
          handleClose();
        }
      },
    });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const dropProfileTypesOptions = useMemo(() => {
    return superDropProfile?.profiles
      .filter((profile) => profile.key !== order.dropProfile?.key)
      .map((profile) => ({
        value: profile.id,
        label: profile.name,
      }));
  }, [superDropProfile, order]);

  useEffect(() => {
    if (superDropProfile?.profiles.length) {
      const defaultCustomProfile = superDropProfile.profiles.find(
        (profile) => profile.isDefault && profile.profileType === DropProfileType.custom
      ) as DropProfile;

      if (defaultCustomProfile?.typeDetails) {
        const customTimeSlots = defaultCustomProfile.typeDetails as CustomDropProfile;
        setDoubleTimeSlots(
          customTimeSlots.timeSlots?.map((slot) => ({
            label: `${slot.from}-${slot.to}`,
            value: `${slot.from}-${slot.to}`,
            to: slot.to,
            from: slot.from,
          })) ?? []
        );
      }
    }
  }, [superDropProfile, order]);

  const timeSlots = useMemo(() => {
    if (date === dateSlots[0].value) {
      return isCustomProfileType
        ? doubleTimeSlots.filter((option) => (option as any).to >= getNearestNext30Min())
        : singleTimeSlots.filter((option) => option.value >= getNearestNext30Min());
    } else {
      return isCustomProfileType ? doubleTimeSlots : singleTimeSlots;
    }
  }, [date, dateSlots, doubleTimeSlots, isCustomProfileType, singleTimeSlots]);

  const dateInputLabel = `${isDropLabel ? 'Drop' : 'Pickup'} Date`;

  const timeInputLabel = `${isDropLabel ? 'Drop' : 'Pickup'} Time`;

  return (
    <>
      <HStack
        bg="#EFEFEF"
        py="1"
        px="3"
        borderRadius="4"
        w="max-content"
        onClick={() => {
          if (!order.isRemote) onOpen();
        }}
      >
        <Text fontSize="text-xs">{order.isRemote ? 'Remote' : order?.dropProfile?.name}</Text>
        {order.dropProfile?.profileType === DropProfileType.express && (
          <Svg src={ColoredRocketIcon} width="15px" height="15px" stroke="currentColor" />
        )}
      </HStack>
      <SBModal
        isOpen={isOpen}
        onClose={handleClose}
        header={
          <Box pb="1vh" mb="2">
            <Text>Adjust Drop Profile</Text>
          </Box>
        }
        body={
          <Grid gridTemplateColumns="repeat(12,1fr)" gap="2" rowGap={5}>
            <GridItem colSpan={12}>
              <Controller
                name="dropProfileId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    label="Drop Profile Type"
                    onChange={(option) => {
                      if (!option) return;
                      if (typeof option === 'object' && 'value' in option && 'label' in option) {
                        onChange(option?.value);
                      }
                      setSelectedProfileType(
                        //  @ts-expect-error dummy
                        dropProfiles?.find((profile) => profile.id === option?.value)?.profileType
                      );
                    }}
                    options={dropProfileTypesOptions}
                    error={errors.dropProfileId?.message}
                  />
                )}
              />
            </GridItem>
            <GridItem colSpan={12}>
              <HStack alignItems="end" justifyContent="space-between">
                {!isStandardProfileType && (
                  <Controller
                    name="date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        label={dateInputLabel}
                        onChange={(option) => {
                          if (!option) return;
                          if (
                            typeof option === 'object' &&
                            'value' in option &&
                            'label' in option
                          ) {
                            onChange(option?.value);
                          }
                        }}
                        options={dateSlots}
                        value={dateSlots.find((date) => date.value === value) ?? ''}
                        error={errors.date?.message}
                      />
                    )}
                  />
                )}
                <Controller
                  name="time"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      label={timeInputLabel}
                      onChange={(option) => {
                        if (!option) return;
                        if (typeof option === 'object' && 'value' in option && 'label' in option) {
                          onChange(option?.value);
                        }
                      }}
                      options={timeSlots}
                      value={timeSlots.find((time) => time.value === value) ?? ''}
                      error={errors.time?.message}
                    />
                  )}
                />
              </HStack>
            </GridItem>
          </Grid>
        }
        //  @ts-expect-error dummy
        scrollBehavior="outside"
        size="xl"
        handleConfirm={handleSubmit(handleConfirm)}
      />
    </>
  );
}
