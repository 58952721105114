import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import {
  GET_DROP_PROFILES,
  GET_SIMPLE_DROP_PROFILES,
  CREATE_DROP_PROFILE,
  UPDATE_DROP_PROFILE,
  REMOVE_DROP_PROFILE,
  MARK_AS_PRIMARY_QUERY,
  TOGGLE_PROFILE_STATUS_QUERY,
  UPDATE_SUPER_DROP_PROFILE,
  MARK_SUPER_PROFILE_AS_PRIMARY_QUERY,
  REMOVER_SUPER_DROP_PROFILE,
  GET_SUPER_DROP_PROFILE_BY_CITY,
  UPDATE_SUPER_DROP_PROFILE_CITIES,
} from './dropProfiles.graphql';
import type {
  CreateDropProfileInput,
  DropProfile,
  DropProfileResponse,
  SuperDropPorfile,
  UpdateDropProfileInput,
  UpdateSuperDropProfilesInput,
} from './dropProfiles.types';

export function useDropProfilesQuery(retailerId: string) {
  const [getDropProfiles, { data, loading, error, ...rest }] = useLazyQuery<{
    citiesDropProfiles: SuperDropPorfile[];
  }>(GET_DROP_PROFILES, {
    variables: { retailerId },
  });

  return {
    getDropProfiles,
    dropProfiles: data?.citiesDropProfiles,
    loading,
    error,
    ...rest,
  };
}

export function useSimpleDropProfilesQuery(props: { retailerId: string; isActive: boolean }) {
  const [getSimpleDropProfiles, { data, loading, error }] = useLazyQuery<{
    dropProfiles: DropProfile[];
  }>(GET_SIMPLE_DROP_PROFILES, {
    variables: props,
  });

  return {
    getSimpleDropProfiles,
    dropProfiles: data?.dropProfiles ?? [],
    loading,
    error,
  };
}

export function useSuperDropProfileByCityQuery(props: {
  retailerId: string;
  isActive: boolean;
  city?: string;
}) {
  const [getSuperDropProfile, { data, loading, error }] = useLazyQuery<{
    superDropProfile: SuperDropPorfile;
  }>(GET_SUPER_DROP_PROFILE_BY_CITY, {
    variables: props,
  });

  return {
    getSuperDropProfile,
    superDropProfile: data?.superDropProfile ?? null,
    loading,
    error,
  };
}

export const useCreateDropProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { createDropProfile: DropProfileResponse },
    { createDropProfileInput: CreateDropProfileInput }
  >(CREATE_DROP_PROFILE);

  async function createDropProfile(payload: CreateDropProfileInput) {
    return mutate({
      variables: {
        createDropProfileInput: {
          ...payload,
          billingSlab: payload.billingSlab ? Number(payload.billingSlab) : null,
          drivingTimeInMins: Number(payload.drivingTimeInMins),
          maximumEndOffsetInMins: Number(payload.maximumEndOffsetInMins),
        },
      },
      refetchQueries: ['CitiesDropProfiles'],
    });
  }

  return {
    createDropProfile,
    reset,
    loading,
    error,
  };
};

export const useUpdateDropProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { updateDropProfile: DropProfileResponse },
    { updateDropProfileInput: UpdateDropProfileInput }
  >(UPDATE_DROP_PROFILE);

  async function updateDropProfile(payload: UpdateDropProfileInput) {
    return mutate({
      variables: {
        updateDropProfileInput: {
          ...payload,
          billingSlab: payload.billingSlab ? Number(payload.billingSlab) : null,
          cutoffTime:
            payload.cutoffTime?.split(':').length === 3
              ? payload.cutoffTime.replace(/:00$/, '')
              : payload.cutoffTime,
          expressProfile: payload.expressProfile
            ? {
                drivingTimeInMins: Number(payload.expressProfile.drivingTimeInMins),
                maximumEndOffsetInMins: Number(payload.expressProfile.maximumEndOffsetInMins),
              }
            : null,
          standardProfile: payload.standardProfile
            ? {
                endOfDayTime: payload.standardProfile.endOfDayTime,
              }
            : null,
          customProfile: payload.customProfile
            ? {
                leadTimeInHours: payload.customProfile.leadTimeInHours
                  ? Number(payload.customProfile.leadTimeInHours)
                  : null,
                timeSlotsDurationInDays: payload.customProfile.timeSlotsDurationInDays
                  ? Number(payload.customProfile.timeSlotsDurationInDays)
                  : null,
                preScheduleSlot:
                  payload.customProfile.preScheduleSlot?.from &&
                  payload.customProfile.preScheduleSlot?.to
                    ? {
                        from: payload.customProfile.preScheduleSlot.from,
                        to: payload.customProfile.preScheduleSlot.to,
                      }
                    : null,
                timeSlots: !payload.customProfile.timeSlots?.length
                  ? null
                  : payload.customProfile.timeSlots.map((timeSlot) => ({
                      from: timeSlot.from,
                      to: timeSlot.to,
                    })),
              }
            : null,
          cities:
            payload.cities?.map((city) => ({
              city: city.city,
              cutoffTime: city.cutoffTime?.substring(0, 5),
              billingSlab: city.billingSlab ? Number(city.billingSlab) : null,
            })) ?? null,
        },
      },
      refetchQueries: ['CitiesDropProfiles'],
    });
  }

  return {
    updateDropProfile,
    reset,
    loading,
    error,
  };
};

export const useRemoveDropProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { removeDropProfile: { success: boolean; message: string } },
    { id: string }
  >(REMOVE_DROP_PROFILE);

  async function removeDropProfile(id: string) {
    return mutate({
      variables: {
        id,
      },
      refetchQueries: ['CitiesDropProfiles'],
    });
  }

  return {
    removeDropProfile,
    reset,
    loading,
    error,
  };
};

export const useRemoveSuperDropProfileMutation = () => {
  const [mutate, { loading, error, reset }] = useMutation<
    { removeSuperDropProfile: { message: string } },
    { removeSuperDropProfileId: string }
  >(REMOVER_SUPER_DROP_PROFILE);

  async function removeSuperDropProfile(id: string) {
    return mutate({
      variables: {
        removeSuperDropProfileId: id,
      },
      refetchQueries: ['CitiesDropProfiles'],
    });
  }

  return {
    removeSuperDropProfile,
    reset,
    loading,
    error,
  };
};

export const useSetPrimary = () => {
  const [mutate, { loading, error }] = useMutation<
    { markAsPrimary: { message: string } },
    {
      markPrimaryInput: {
        id: string;
        retailerId: string;
        groupId?: string;
      };
    }
  >(MARK_AS_PRIMARY_QUERY, {
    refetchQueries: ['CitiesDropProfiles'],
    onCompleted(data) {
      toast.success(data.markAsPrimary.message);
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};

export const useToggleProfileStatus = () => {
  const [mutate, { loading, error }] = useMutation<
    { updateDropProfile: { message: string } },
    {
      updateDropProfileInput: {
        id: string;
        isActive: boolean;
      };
    }
  >(TOGGLE_PROFILE_STATUS_QUERY, {
    refetchQueries: ['CitiesDropProfiles'],
    onCompleted(data) {
      toast.success(data.updateDropProfile.message);
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};

export const useUpdateSuperDropProfiles = () => {
  const [mutate, { loading, error }] = useMutation<
    { updateSuperDropProfiles: { message: string } },
    { updateSuperDropProfilesInput: UpdateSuperDropProfilesInput }
  >(UPDATE_SUPER_DROP_PROFILE, {
    refetchQueries: ['CitiesDropProfiles'],
    onCompleted(data) {
      toast.success(data.updateSuperDropProfiles.message);
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};

export const useSetSuperDropProfilePrimary = () => {
  const [mutate, { loading, error }] = useMutation<
    { markAsPrimary: { message: string } },
    {
      markPrimaryInput: {
        id: string;
        retailerId: string;
      };
    }
  >(MARK_SUPER_PROFILE_AS_PRIMARY_QUERY, {
    refetchQueries: ['CitiesDropProfiles'],
    onCompleted(data) {
      toast.success(data.markAsPrimary.message);
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};

export const useUpdateSuperDropProfileCities = () => {
  const [mutate, { loading, error }] = useMutation<
    { updateSuperDropProfileCities: { message: string } },
    {
      updateSuperDropProfileInput: {
        groupId: string;
        cities: string[];
      };
    }
  >(UPDATE_SUPER_DROP_PROFILE_CITIES, {
    refetchQueries: ['CitiesDropProfiles'],
    onCompleted(data) {
      toast.success(data.updateSuperDropProfileCities.message);
    },
  });

  return {
    mutate,
    loading,
    error,
  };
};
